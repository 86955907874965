import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
// import MySwiper2 from '../../../components/Swiper2'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// images
import a1 from '../../../assets/images/gates/arched/a1.jpg'
import a2 from '../../../assets/images/gates/arched/a2.jpg'
import a3 from '../../../assets/images/gates/arched/a3.jpg'
import a4 from '../../../assets/images/gates/arched/a4.jpg'
import a5 from '../../../assets/images/gates/arched/a5.jpg'
import a6 from '../../../assets/images/gates/arched/a6.jpg'
import a7 from '../../../assets/images/gates/arched/a7.jpg'
import a8 from '../../../assets/images/gates/arched/a8.jpg'
import a9 from '../../../assets/images/gates/arched/a9.jpg'
import a10 from '../../../assets/images/gates/arched/a10.jpg'
import a11 from '../../../assets/images/gates/arched/a11.jpg'
import a12 from '../../../assets/images/gates/arched/a12.jpg'
import a13 from '../../../assets/images/gates/arched/a13.jpg'
import a14 from '../../../assets/images/gates/arched/a14.jpg'

const gates = [
  {
    label: 'A1',
    img: a1,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A1.pdf',
  },
  {
    label: 'A2',
    img: a2,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A2.pdf',
  },
  {
    label: 'A3',
    img: a3,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A3.pdf',
  },
  {
    label: 'A4',
    img: a4,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A4.pdf',
  },
  {
    label: 'A5',
    img: a5,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A5.pdf',
  },
  {
    label: 'A6',
    img: a6,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A6.pdf',
  },
  {
    label: 'A7',
    img: a7,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A7.pdf',
  },
  {
    label: 'A8',
    img: a8,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A8.pdf',
  },
  {
    label: 'A9',
    img: a9,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A9.pdf',
  },
  {
    label: 'A10',
    img: a10,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A10.pdf',
  },
  {
    label: 'A11',
    img: a11,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A11.pdf',
  },
  {
    label: 'A12',
    img: a12,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A12.pdf',
  },
  {
    label: 'A13',
    img: a13,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A13.pdf',
  },
  {
    label: 'A14',
    img: a14,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A14.pdf',
  },
]

class archedGatesPage extends React.Component {
  render() {
    const siteTitle = 'Arched Gates - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'Arched Gate in Arlington, TX. Call . We Install and Service Arched Gates in the Dallas-Fort Worth, TX Area'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="J&J Automatic Gate Service & Design"
          description={siteDescription}
        />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Arched Gate Designs</h1>
            <hr />
          </div>
        </div>

        <div className=" container d-padding-t d-padding-b text-center arched-gates">
          <div className="row">
            <div className="col-md-12 mb-5">
              <p className="mx-auto">
                J&J Gate Services offers many gate types and designs. The
                following designs are the arched gate we build and install.
                These are just some of the designs we have available and we’re
                happy to help you create the custom design you have in mind!{' '}
                <strong>
                  Click any image to download or print a pdf version of the
                  design.
                </strong>{' '}
                Contact us at for arched gate installation in the Dallas-Fort
                Worth, TX area.
              </p>
            </div>
            {gates.map(gate => (
              <div className="col-md-4">
                <div className="img-thumbnail shadow my-3 pt-3">
                  <a href={gate.pdf} target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage src={gate.img} alt="Bell Gate" />
                  </a>
                  <h5 class="mt-3">{gate.label}</h5>
                </div>
              </div>
            ))}
            {/* <MySwiper2 slidesperview={3}>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a1} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A1</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a2} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A2</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a3} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A3</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a4} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A4</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a5} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A5</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a6} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A6</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a7} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A7</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A8.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a8} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A8</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a9} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A9</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A10.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a10} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A10</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/pdfgates/A11.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a11} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A11</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A12.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a12} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A12</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A13.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a13} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A13</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085095/jjgates/pdfgates/A14.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={a14} alt="Arched Gate" />
                </a>

                <h5 class="mt-0">A14</h5>
              </div>
            </MySwiper2> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default archedGatesPage

export const archedGatesPageQuery = graphql`
  query archedGatesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
